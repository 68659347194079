nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  align-items: center;
}

nav li:not(:last-child) {
  margin-right: 1em;
}

nav li:last-child {
  margin-left: auto;
}

nav h2 {
  margin: 0;
}

.active {
  font-weight: bold;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  /* border: solid 1px black; */
  text-align: center;
}
